import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

import KnMenu from '../../../shared/components/KnMenu.vue';

import { paginationMixin } from '../../../shared/mixins/paginationMixin';
// import { Actions } from '../../../shared/helpers/permissionContants';
// import { can } from '../../../shared/helpers/permissionsUtils';
import {
  getSubjectHolder,
  getTrainingField,
} from '../../helpers/syllabusOptions';
import { getFullName } from '../../../shared/helpers/dataUtils';
export default {
  name: 'KnFilteredSyllabusTable',
  components: { KnMenu },
  mixins: [paginationMixin],
  props: {
    tableTitle: {
      type: String,
      default: '',
    },
    rememberLastPage: {
      type: Boolean,
      default: false,
    },
    newButtonLabel: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disableBtnCreate: {
      type: Boolean,
      default: false,
    },
    showBottomAction: {
      type: Boolean,
      default: false,
    },
    showInactiveItems: {
      type: Boolean,
      default: false,
    },
    bottomActionText: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paginationCount: {
      type: Number,
      default: 0,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: 1,
      search: null,
      expanded: [],
      singleExpand: true,
      subjectHeaders: [
        {
          text: 'Materia',
          value: 'nombre',
          class: 'purple--text',
        },
        {
          text: 'Titular',
          value: 'titular_materia',
          class: 'purple--text',
        },
        {
          text: 'Extracurricular',
          value: 'extracurricular',
          class: 'purple--text',
        },
        {
          text: 'Campos formativos',
          value: 'campos_formativos',
          class: 'purple--text',
        },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      loadingExpanded: false,
    };
  },
  computed: {
    ...mapState(['currentPage']),
    ...mapGetters(['tutorHasDebts', 'hasAdminPermission']),
    bottomText() {
      return this.bottomActionText
        ? this.bottomActionText
        : `Ver ${this.tableTitle} inactivas`;
    },
    section() {
      if (this.newButtonLabel.includes('Calificación')) {
        return 'Calificaciones por materia';
      }
      return '';
    },
    routeName() {
      if (this.newButtonLabel.includes('Calificación')) {
        return 'Nuevo Calificacion';
      }
      return '';
    },
    currentItems() {
      // console.log('Items recibidos', this.items);
      return this.items;
    },
  },
  watch: {
    paginationCount: function () {
      if (this.rememberLastPage) {
        this.page = this.currentPage || 1;
        this.setCurrentPage(this.currentPage);
      } else {
        this.page = 1;
      }
    },
  },
  methods: {
    ...mapMutations(['setSearch', 'setSelectedId', 'setCurrentPage']),
    ...mapActions(['clearSearch']),
    // menuOptions(item) {
    //   return [
    //     {
    //       title: 'Ver calificaciones',
    //       action: () => this.goTo(`Editar ${this.section}`, { entity: item }),
    //       section: ['Calificaciones por materia'],
    //       permissions: [Actions.change],
    //       active: true,
    //       disabled: false,
    //     },
    //   ]
    //     .filter(
    //       (option) =>
    //         option.section.some((s) => s === this.section) &&
    //         option.active === !this.showInactiveItems
    //     )
    //     .map((option) => {
    //       option.permissions && option.permissions.length
    //         ? (option.disabled = !option.permissions.some((p) => {
    //             switch (this.section) {
    //               case 'Ingresos':
    //                 return can({
    //                   actions: [p],
    //                   resource: 'ingreso',
    //                 });
    //               case 'Egresos':
    //                 return can({
    //                   actions: [p],
    //                   resource: 'egreso',
    //                 });
    //               default:
    //                 return can({ actions: [p], resource: this.section });
    //             }
    //           }))
    //         : (option.disabled = false);
    //       return option;
    //     });
    // },
    goTo(routeName, params) {
      // if (routeName.includes('Alumno')) {
      //   this.setSelectedId(params.entity.id);
      //   return this.$router.push({
      //     name: routeName,
      //     params: { id: params.entity.id },
      //   });
      // }
      // if (
      //   routeName.includes('Detalle Orden') ||
      //   routeName.includes('Solicitar Cancelacion')
      // ) {
      //   this.setSelectedId(params.entity.id);
      //   return this.$router.push({
      //     name: routeName,
      //     params: { id: params.entity.id },
      //   });
      // }
      // if (routeName.includes('Inventario')) {
      //   this.setSelectedId(params.entity.id);
      //   return this.$router.push({
      //     name: routeName,
      //     params: { id: params.entity.id },
      //   });
      // }
      return this.$router.push({ name: routeName, params: params });
    },
    emitAction(value) {
      this.$emit('action', value);
    },
    emitAction2(value) {
      this.$emit('action2', value);
    },
    emitShowAction() {
      this.clearSearch();
      this.$emit('showAction');
    },
    emitActionResults(value) {
      this.$emit('resultsAction', value);
    },
    emitChangePage() {
      this.$emit('onChangePage', this.page);
    },
    emitSearch() {
      // console.log('Click en lupa');
      this.$emit('onSearch');
    },
    emitClearSearch() {
      this.clearSearch();
      this.$emit('onClearSearch');
    },
    async fetchData() {
      this.loadingExpanded = true;
      // console.log('Se expandió un item', this.expanded);
      if (this.expanded.length) {
        const item = this.expanded[0];
        const syllabus = {
          id: item.id,
          nombre: item.nombre,
          ciclo_escolar: item.ciclo_escolar,
        };
        this.filters.syllabusFilter = syllabus;

        item.filters = this.filters;

        const subjects = [
          ...item.materias_curriculares,
          ...item.materias_extracurriculares,
        ];

        for (const subject of subjects) {
          if (typeof subject.titular_materia === 'number') {
            const { ok, data } = await getSubjectHolder(
              subject.titular_materia
            );
            if (ok) {
              const fullName = data.datos_personales
                ? getFullName(data.datos_personales)
                : 'Sin nombre';
              subject.titular_materia = fullName;
            }
          }

          if (typeof subject.campos_formativos === 'object') {
            let fieldNames = [];
            for (const field of subject.campos_formativos) {
              // console.log('field?');
              const { ok, data } = await getTrainingField(field);
              if (ok) {
                fieldNames.push(data.nombre);
              }
            }
            subject.campos_formativos = fieldNames.length
              ? fieldNames.join(', ')
              : '';
          }
        }
        item.materias = subjects;
        // console.log('Item', item);
      }
      setTimeout(() => (this.loadingExpanded = false), 500);
    },
  },
};
