import { mapGetters, mapState } from 'vuex';
import { fetchSchoolCycles } from '../../../configuration/helpers/KnGroupsOptions';
import {
  fetchSchoolLevels,
  fetchEvaluationPeriods,
  fetchSyllabus,
} from '../../helpers/syllabusOptions';
export default {
  name: 'KnAuthorizationFilter',
  components: {},
  mixins: [],
  props: {
    showSchoolYear: {
      type: Boolean,
      default: true,
    },
    showSchoolLevel: {
      type: Boolean,
      default: true,
    },
    showSyllabus: {
      type: Boolean,
      default: true,
    },
    showEvaluationPeriod: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      cycles: [],
      levels: [],
      syllabus: [],
      periods: [],
      selectedSchoolYear: null,
      selectedSchoolLevel: null,
      selectedSyllabus: null,
      selectedEvaluationPeriod: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['currentSchoolYear', 'currentSchoolLevel']),
  },
  watch: {
    selectedSchoolYear: {
      async handler() {
        this.selectedSyllabus = null;
        await this.setSyllabus();
      },
    },
    selectedSchoolLevel: {
      async handler() {
        this.selectedSyllabus = null;
        await this.setSyllabus();
      },
    },
    selectedSyllabus: {
      async handler() {
        // console.log('Cambio de plan');
        this.selectedEvaluationPeriod = null;
        await this.setEvaluationPeriods();
      },
    },
    institutionId: {
      async handler() {
        await this.fetchData();
        this.emitFilterChange();
      },
    },
  },
  async created() {
    await this.fetchData();
    this.emitFilterChange();
  },
  methods: {
    async setSchoolYears() {
      if (this.showSchoolYear) {
        const { ok: okSchoolCycles, data: dataSchoolCycles } =
          await fetchSchoolCycles({
            institutionId: this.institutionId,
            systemStatus: true,
            limit: 100,
          });
        this.cycles = okSchoolCycles ? dataSchoolCycles : [];
        this.selectedSchoolYear = this.currentSchoolYear;
      }
    },
    async setSchoolLevels() {
      if (this.showSchoolLevel) {
        const { ok: okSchoolLevels, data: dataSchoolLevels } =
          await fetchSchoolLevels({
            institutionId: this.institutionId,
            name:
              this.currentSchoolLevel !== 'Todos'
                ? this.currentSchoolLevel
                : null,
            systemStatus: true,
            limit: 100,
          });

        this.levels = okSchoolLevels ? dataSchoolLevels : [];
        this.selectedSchoolLevel =
          this.currentSchoolLevel !== 'Todos' && this.levels.length
            ? this.levels[0]
            : null;
      }
    },
    async setSyllabus() {
      if (this.showSyllabus) {
        const { ok: okSyllabus, data: dataSyllabus } = await fetchSyllabus({
          institutionId: this.institutionId,
          schoolCycleId: this.selectedSchoolYear
            ? this.selectedSchoolYear.id
            : null,
          schoolLevel: this.selectedSchoolLevel
            ? this.selectedSchoolLevel.nombre
            : null,
          systemStatus: true,
          limit: 2000,
        });
        this.syllabus = okSyllabus ? dataSyllabus : [];
      }
    },
    async setEvaluationPeriods() {
      if (this.showEvaluationPeriod) {
        if (!this.selectedSyllabus) {
          // console.log('plan es null');
          const { ok: okEvaluationPeriod, data: dataEvaluationPeriod } =
            await fetchEvaluationPeriods({
              institutionId: this.institutionId,
              systemStatus: true,
              startDate: this.selectedSchoolYear
                ? this.selectedSchoolYear.fecha_inicio
                : null,
              endDate: this.selectedSchoolYear
                ? this.selectedSchoolYear.fecha_fin
                : null,
              limit: 100,
            });

          this.periods = okEvaluationPeriod ? dataEvaluationPeriod : [];
        } else {
          // console.log('No es null', this.selectedSyllabus);
          this.selectedEvaluationPeriod = null;
          this.periods = this.selectedSyllabus.periodo_evaluacion;
        }
      }
    },
    async fetchData() {
      this.loading = true;
      // Se deben obtener los valores necesarios para
      // llenar los selects
      await this.setSchoolYears();
      await this.setSchoolLevels();
      await this.setSyllabus();
      await this.setEvaluationPeriods();
      this.loading = false;
    },
    emitFilterChange() {
      this.$emit('onFilterChange', {
        schoolYearFilter: this.selectedSchoolYear,
        schoolLevelFilter: this.selectedSchoolLevel,
        syllabusFilter: this.selectedSyllabus,
        evaluationPeriodFilter: this.selectedEvaluationPeriod,
      });
    },
  },
};
