import { mapState } from 'vuex';
import KnAuthorizationFilter from '../../components/KnAuthorizationFilter/KnAuthorizationFilter.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnFilteredGroupsTable from '../../components/KnFilteredGroupsTable/KnFilteredGroupsTable.vue';
import KnFilteredSyllabusTable from '../../components/KnFilteredSyllabusTable/KnFilteredSyllabusTable.vue';
import {
  insufficientPermissionsMessage,
  canAdd,
} from '../../../shared/helpers/permissionsUtils';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import { fetchSyllabus } from '../../helpers/syllabusOptions';
import { fetchGroups } from '../../helpers/reportCardOptions';
export default {
  name: 'GradeAuthorizationIndex',
  components: {
    KnAuthorizationFilter,
    KnFilteredGroupsTable,
    KnFilteredSyllabusTable,
    KnTabs,
  },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      valueDeterminate: 50,
      tabs: [
        { name: 'Grupos', value: 50 },
        { name: 'Materias', value: 100 },
      ],
      syllabus: [],
      groups: [],
      headers: [
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        {
          text: 'Nivel educativo',
          value: 'nivel_educativo.nombre',
          class: 'purple--text',
        },
        {
          text: 'Ciclo escolar',
          value: 'ciclo_escolar.dato',
          class: 'purple--text',
        },
        { text: 'ID', value: 'id', class: 'purple--text' },
      ],
      groupHeaders: [
        { text: 'Nombre', value: 'nombre_grupo', class: 'purple--text' },
        {
          text: 'Titular',
          value: 'profesores[0].datos_personales.primer_nombre',
          class: 'purple--text',
        },
        {
          text: 'Ciclo escolar',
          value: 'ciclo_escolar.dato',
          class: 'purple--text',
        },
        {
          text: 'Plan de estudios',
          value: 'plan_estudios.nombre',
          class: 'purple--text',
        },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: 'Acciones', value: 'acciones', class: 'purple--text' },
      ],
      inactiveItems: [],
      showInactive: false,
      loading: false,
      selectedAction: '',
      itemToEdit: null,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
      showDuplicate: false,
      duplicateName: '',
      filters: {
        schoolYearFilter: null,
        schoolLevelFilter: null,
        syllabusFilter: null,
        evaluationPeriodFilter: null,
      },
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
    showTable() {
      return this.filters && this.filters.schoolYearFilter;
    },
  },
  watch: {
    filters: {
      async handler() {
        if (this.showTable) {
          if (this.valueDeterminate === 50) {
            await this.getPaginatedGroups(1);
          } else {
            await this.getPaginatedSyllabus(1);
          }
        }
      },
    },
    valueDeterminate: {
      async handler() {
        if (this.showTable) {
          if (this.valueDeterminate === 50) {
            await this.getPaginatedGroups(1);
          } else {
            await this.getPaginatedSyllabus(1);
          }
        }
      },
    },
  },
  async created() {
    // await this.getPaginatedSyllabus(1);
  },
  methods: {
    canAdd: canAdd,
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    setFilters(filters) {
      this.filters = { ...filters };
      // console.log('filtros', this.filters);
    },
    async getPaginatedGroups(page = 1) {
      this.setPaginationPage(page);
      if (!this.loading) {
        this.groups = [];
        this.loading = true;
        const { ok, data, message, count } = await fetchGroups({
          institutionId: this.institutionId,
          groupName: this.search,
          systemStatus: !this.showInactive,
          schoolCycleId: this.filters.schoolYearFilter
            ? this.filters.schoolYearFilter.id
            : null,
          syllabusId: this.filters.syllabusFilter
            ? this.filters.syllabusFilter.id
            : null,
          schoolLevel: this.filters.schoolLevelFilter
            ? this.filters.schoolLevelFilter.id
            : null,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          const groupsResults = data;
          // console.log('GroupsResult', groupsResults);
          this.groups.push(...groupsResults);
        } else {
          console.error('Error al obtener grupos. ', message);
        }
        this.loading = false;
      }
    },
    async getPaginatedSyllabus(page = 1) {
      this.setPaginationPage(page);
      if (!this.loading) {
        this.syllabus = [];
        this.loading = true;
        const { ok, data, message, count } = await fetchSyllabus({
          institutionId: this.institutionId,
          name: this.search,
          systemStatus: !this.showInactive,
          schoolCycleId: this.filters.schoolYearFilter
            ? this.filters.schoolYearFilter.id
            : null,
          schoolLevel: this.filters.schoolLevelFilter
            ? this.filters.schoolLevelFilter.nombre
            : null,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          const syllabusResults = data;
          // console.log('SyllabusResult', syllabusResults);
          this.syllabus.push(...syllabusResults);
        } else {
          console.error('Error al obtener planes. ', message);
        }
        this.loading = false;
      }
    },
  },
};
